import { NextSeo } from 'next-seo';

interface HeaderMetaProps {
    url?: string;
    title?: string;
    description?: string;
    image?: string;
    canonical?: boolean;
    type?: string;
}

const defaultProps = {
    url: 'https://www.vinovest.co',
    title: 'Vinovest | Investing in Wine as an Alternative Asset',
    description: `It's time to invest in fine wine.`,
    image: 'https://user-images.githubusercontent.com/41701884/125706147-27f0709a-d7c6-4e06-b4ea-1c84eac2022a.png',
    type: `website`
};

const HeaderMetaTags = ({ url, title, description, image, canonical, type }: HeaderMetaProps) => {
    return (
        <>
            <NextSeo
                title={title || defaultProps.title}
                description={description || defaultProps.description}
                canonical={canonical && url ? url.split('?')[0] : undefined}
                openGraph={{
                    url: url || defaultProps.url,
                    title: title || defaultProps.title,
                    description: description || defaultProps.description,
                    images: [{ url: image || defaultProps.image }],
                    type: type || defaultProps.type
                }}
            />
        </>
    );
};

export default HeaderMetaTags;
