import React, { useEffect } from 'react';
import dynamic from 'next/dynamic';

import { GetServerSideProps } from 'next';
import { getCookieAuthentication } from '~/utils/getCookieAuthentication';
import { PosthogHelper } from '~/helpers/PosthogHelper';
import { HomePageDark } from '~/components/views/Home/HomePageDark';
import { useRouter } from 'next/router';
import HeaderMetaTags from '~/components/modules/Header/HeaderMetaTags';

const AppContainer = dynamic(() => import('~/containers/AppContainer/AppContainer'));

const Home: React.FC = () => {
    const router = useRouter();
    useEffect(() => {
        PosthogHelper.capture('Homepage-nextjs', { bucket: 'test' });
    });

    return (
        <AppContainer>
            <HeaderMetaTags
                url={`${process.env.NEXT_PUBLIC_HOST_ENV}${router.asPath}`}
                title={'Vinovest | Wine Investment'}
                type={'website'}
                description={
                    'With 13.6% annualized returns over the past 15 years, fine wine has outperformed most global equities and is less volatile than real estate or gold.'
                }
                canonical
            />
            <HomePageDark />
        </AppContainer>
    );
};

// eslint-disable-next-line @typescript-eslint/require-await
export const getServerSideProps: GetServerSideProps = async ({ req }) => {
    const isClientNav = Boolean(req.headers['x-nextjs-data']);

    const authenticationParams = getCookieAuthentication(req);
    if (!isClientNav && authenticationParams.userId) {
        return {
            redirect: {
                destination: '/dashboard',
                permanent: false
            }
        };
    }

    return { props: {} };
};

export default Home;
